import {Component, ElementRef, Input} from '@angular/core';
import {Subscription} from "rxjs";
import {fromEvent} from "rxjs/internal/observable/fromEvent";

type ButtonStyle =
  '' |
  'button--primary'|
  'button--secondary'|
  'button--danger'|
  'button--grey'

type IconAlign =
  '' |
  'button--icon-right'|
  'button--icon-left'

type ButtonSize =
  '' |
  'button--small'

type ButtonType =
  'button' |
  'submit'

type SpecialButtonType =
  'journeyNext' |
  'journeyPrev'
@Component({
  selector: 'four-lead-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})

export class ButtonComponent {
  @Input() buttonStyle: ButtonStyle = 'button--primary';
  @Input() iconAlign: IconAlign = '';
  @Input() icon = '';
  @Input() disableIconMobile = false;
  @Input() buttonSize: ButtonSize = '';
  @Input() fullWidth = false;
  @Input() fullWidthMobile = false;
  @Input() buttonType: ButtonType = 'button';
  @Input() iconOnly = false;
  @Input() disabled = false;
  @Input() specialType: SpecialButtonType | undefined = undefined;
  @Input() noData = false;
  @Input() fillHeight = false;

  @Input() keyControl: string = '';
  keypress: Subscription | undefined;

  constructor(private elementRef: ElementRef) {
    this.keypress = fromEvent<KeyboardEvent>(document, 'keydown')
        .subscribe((next: KeyboardEvent) => {
          this.handleKeyPress(next);
        });
  }

  private handleKeyPress(event: KeyboardEvent) {
    if (!(event.target instanceof HTMLBodyElement)) return;
    if (event.key === this.keyControl && !this.disabled) {
        this.elementRef.nativeElement.click();
    }
  }

  protected readonly undefined = undefined;
}
