import {Component, QueryList, ViewChildren} from '@angular/core';
import {ModalService} from "../../../services/modal/modal.service";
import {AuthService} from "../../../services/auth/auth.service";
import {DotState} from "../../components/atoms/state-dot/state-dot.component";
import {PostIt} from "../../components/organism/post-it/post-it-button/post-it-button.component";
import {PostItModalComponent} from "../../components/organism/post-it/post-it-modal/post-it-modal.component";
import {RatioTab} from "../../components/molecule/ratio-tab/ratio-tab.component";
import {
  ExpansionPanelWrapperComponent
} from "../../components/organism/expansion-panel/wrapper/expansion-panel-wrapper.component";
import {TutorialModalComponent} from "../../../components/modal/tutorial-modal/tutorial-modal.component";

@Component({
  selector: 'four-lead-design-summary',
  templateUrl: './design-summary.component.html',
  styleUrls: ['./design-summary.component.scss'],
})
export class DesignSummaryComponent {

  constructor(private authService: AuthService, private modalService: ModalService) {}

  public isLoggedIn = this.authService.getLoginStatus();
  public logout() {
    this.authService.logout();
  }

  contextOpen = false;

  public dots: DotState[] = [
    'done',
    'done',
    'done',
    'active',
    'waiting',
    'waiting',
    'waiting',
    'waiting',
    'waiting'
  ]

  public chipMapTexts: string[] = [
    'Text1',
    'test text',
    'hallo',
    'chip',
    'choose this one',
    'lorem ipsum sol ardet',
    'Latein',
    'idee für die chips',
    '4 oder auch four, vielleicht auch für',
    'lead, leitung, leiter?'
  ]
  public chipMapError = false;

  public postIts: PostIt[] = [
    {
      color: '',
      text: 'normal'
    },
    {
      color: 'red',
      text: 'red'
    },
    {
      color: 'green',
      text: 'green'
    },
    {
      color: 'blue',
      text: 'blue'
    },
    {
      color: 'purple',
      text: 'purple'
    },
  ]

  public selectedRatio: null | number = null;

  public ratioTabs: RatioTab[] = [
    {
      title: 'Nur Title'
    },
    {
      title: 'Title + Text',
      text: 'Der Text'
    },
    {
      title: 'selected'
    },
    {
      title: 'Viel zu langer Text',
      text: 'Der Text ist wesentlich zu lang und kann deswegen nicht koplett dargestellt werden glaube ich zumidnest oder ist das etwa anders? oder ist das etwa anders? oder ist das etwa anders? oder ist das etwa anders? oder ist das etwa anders?'
    },
    {
      title: 'Viel zu langer Title,  ähnlich wie beim text ist das einfach zu viel für die gegebene Breite glaube ich zumidnest oder ist das etwa anders? oder ist das etwa anders? oder ist das etwa anders? oder ist das etwa anders?',
      text: 'Der Titel ist zu lang'
    },
    {
      title: 'Hier ist beides zu lang, besonders blöd... zu viel für die gegebene Breite glaube ich zumidnest oder ist das etwa anders? oder ist das etwa anders? oder ist das etwa anders? oder ist das etwa anders? oder ist das etwa anders?',
      text: 'Der Text ist wesentlich zu lang und kann deswegen nicht koplett dargestellt werden glaube ich zumidnest oder ist das etwa anders? oder ist das etwa anders? oder ist das etwa anders? oder ist das etwa anders?'
    },
  ]

  public openAccordeon: number | null = null;

  public isTypeText = false;


  @ViewChildren(ExpansionPanelWrapperComponent) expansionWrappers!: QueryList<any>;
  public expansionPanels: any[] = [
    {
      headerIcon: 'assets/img/icons/language.svg',
      title: 'Language preferences',
      subtitle: 'Update your Language lreferences',
      bodyContent: 'Language'
    },
    {
      headerIcon: 'assets/img/icons/mail.svg',
      title: 'Mail notifications',
      subtitle: 'This is short description of current module.',
      bodyContent: 'Mail'
    },
    {
      headerIcon: 'assets/img/icons/login.svg',
      title: 'Login',
      subtitle: 'Manage your login and account security details.',
      bodyContent: 'login'
    },
    {
      headerIcon: 'assets/img/icons/user-edit.svg',
      title: 'Profile',
      subtitle: 'Update your profile picture, first and second name.',
      bodyContent: 'Profile'
    }
  ]

  public openTestModal(withFooter = true, normalConfirmButton = true): void {
    const ref = this.modalService.open(TutorialModalComponent)
    /*ref.componentInstance.cancelButton = 'Close this window';
    ref.componentInstance.headerText = 'Thank you!';
    ref.componentInstance.bodyTitle = 'Thank you for your feedback!';
    ref.componentInstance.bodyText = 'Your feedback helps us to continuously improve our product.';*/
    /*ref.componentInstance.withFooter = withFooter;
    ref.componentInstance.normalConfirmButton = normalConfirmButton;*/
    ref.dismissed.subscribe((result: any) => {
      console.log(result)
    })
  }

  public setWaitingDot(dotIndex: number) {
    this.dots = this.dots.map((value, index) => {
      if (index === dotIndex) {
        return 'active';
      } else if (index > dotIndex) {
        return 'waiting';
      } else {
        return 'done';
      }
    });
  }

  public toggleIsTypeText(): void {
    this.isTypeText = !this.isTypeText;
  }

  public addNewPostItClick(): void {
    this.modalService.open(PostItModalComponent).dismissed.subscribe((result: any) => {
      if (result.success) {
        this.postIts.push(result.postIt)
      }
      console.log(result)
    })
  }

  public handlePostItClick(event: string, index: number): void {
    console.log(event, index, this.postIts[index]);
    if(event === 'edit') {
      const post = this.postIts[index];
      const ref = this.modalService.open(PostItModalComponent)
      ref.componentInstance.postItColor = post.color;
      ref.componentInstance.postItText = post.text;
      ref.dismissed.subscribe((result: any) => {
        if (result.success) {
          this.postIts[index] = result.postIt;
        }
        console.log(result)
      })
    } else {
      this.postIts.splice(index, 1)
    }
  }

  public handleRatioClick(clickIndex: number): void {
    this.selectedRatio = this.selectedRatio === clickIndex? null : clickIndex;
  }

  public handleAccordeonStatusChange(clickIndex: number): void {
    this.openAccordeon = this.openAccordeon === clickIndex? null : clickIndex;
  }

  public handleAccordeonSave(index: number): void {
    console.log('submit on', index)
  }

  public handleAccordeonCancel(index: number): void {
    console.log('cancel on', index)
  }

  public setChipMapError(): void {
    this.chipMapError = true;

    setTimeout(()=>{                           // <<<---using ()=> syntax
      this.chipMapError = false;
    }, 1500);
  }

  public updatePanels(index: number, expanded: boolean) {
    this.expansionWrappers.forEach((expansionWrapper, i) => {
      expansionWrapper.expanded = index === i ? expanded : false;
    })
  }

  public checkPanelExpanded(index: number) {
    if (this.expansionWrappers) {
      return this.expansionWrappers.toArray()[index]?.expanded;
    } else {
      return false
    }
  }
}
