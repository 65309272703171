import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'four-lead-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent {
  @Input() open: boolean = false;
  @Output() openChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() alignY: 'top' | 'bottom' = 'bottom';
  @Input() alignX: 'left' | 'right-space' | 'right' | 'center' = 'left';

  @Input() customStyle: string = '';

  toggleOpenState() {
    this.open = !this.open;
    this.openChange.emit(this.open);
  }
}
