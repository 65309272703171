import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ExpansionPanelHeaderComponent} from "../header/expansion-panel-header.component";
import {ExpansionPanelBodyComponent} from "../body/expansion-panel-body.component";

@Component({
  selector: 'four-lead-expansion-panel-wrapper',
  templateUrl: './expansion-panel-wrapper.component.html',
  styleUrls: ['./expansion-panel-wrapper.component.scss'],
})
export class ExpansionPanelWrapperComponent {
  @Input() expanded = false;
  @Input() disabled: boolean = false;
  @Input() withBorder = false;
  @Output() expandedChange = new EventEmitter<boolean>();
  @ViewChild(ExpansionPanelHeaderComponent) headerComponent!: ExpansionPanelHeaderComponent;
  @ViewChild(ExpansionPanelBodyComponent) bodyComponent!: ExpansionPanelBodyComponent;

  togglePanel() {
    if (this.disabled) {
      return;
    }
    this.expanded = !this.expanded;
    this.expandedChange.emit(this.expanded);
  }
}
