<four-lead-modal [headerIcon]="'assets/img/icons/feedback.svg'"
                 [headerText]="'TUTORIAL_MODAL.HEADER' | translate"
                 [cancelButton]="'TUTORIAL_MODAL.CANCEL' | translate"
                 (output)="handleOutput($event)">
    <h1>
        {{'TUTORIAL_MODAL.TITLE' | translate}}
        <h3 class="subline">{{'TUTORIAL_MODAL.TEXT' | translate}}</h3>
    </h1>
  <video *ngIf="videoUrl"
         class="video-frame"
         [controls]="true"
         [src]="videoUrl"></video>
</four-lead-modal>
