import {Component, Input} from '@angular/core';
import {postItColor} from "../post-it-button/post-it-button.component";
import {ModalService} from "../../../../../services/modal/modal.service";

@Component({
  selector: 'four-lead-post-it-modal',
  templateUrl: './post-it-modal.component.html',
  styleUrls: ['./post-it-modal.component.scss'],
})
export class PostItModalComponent {

  @Input() postItColor: postItColor = '';
  @Input() postItText = '';

  constructor(private modalService: ModalService) {}

  public handleOutput(event: string) {
    this.modalService.close({success: true, postIt: {color: this.postItColor, content: this.postItText}});
  }

  public handleColorClick(color: postItColor) {
    this.postItColor = color === this.postItColor ? '' : color;
  }

  public setTextNew(event: any) {
    this.postItText = event.target.value;
  }
}
