import {Component, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {take} from "rxjs";
import {FeedbackModalComponent} from "../../../../components/modal/feedback-modal/feedback-modal.component";
import {ModalService} from "../../../../services/modal/modal.service";
import {SimpleModalComponent} from "../../../../components/modal/simple-modal/simple-modal.component";
import {TutorialModalComponent} from "../../../../components/modal/tutorial-modal/tutorial-modal.component";
import {ContactModalComponent} from "../../../../components/modal/contact-modal/contact-modal.component";
import {
  CreateFeedbackGQL,
  CreateFeedbackInput
} from "../../../../../graphql/generated";
import {GlobalService} from "../../../../services/global/global.service";
import {Router} from "@angular/router";
import {JourneyNavigationService} from "../journey-navigation/journey-navigation.service";
import {LoadingService} from "../../../../services/loading/loading.service";

@Component({
  selector: 'four-lead-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit{
  mobileHelpOpen = false;
  desktopHelpOpen = false;
  journeyOpen = false;
  loading = false;
  @Input() empty = false;

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const clickedInside = this.elRef.nativeElement.contains(event.target);
    if (!clickedInside && this.mobileHelpOpen) {
      this.mobileHelpOpen = false;
    }
  }

  navLinks = [
    {
      label: 'GLOBAL.DASHBOARD',
      link: '/dashboard',
      icon: 'assets/img/icons/dashboard.svg',
      action: () => {
        document.body.scrollTop = 0;
        this.mobileHelpOpen = false;
      }
    },
    {
      label: 'GLOBAL.JOURNEYS',
      link: '/journeys',
      icon: 'assets/img/icons/journey.svg',
      action: () => {
        document.body.scrollTop = 0;
        this.mobileHelpOpen = false;
      }

    },
    {
      label: 'GLOBAL.TOOLS',
      link: '/tools',
      icon: 'assets/img/icons/tools.svg',
      action: () => {
        document.body.scrollTop = 0;
        this.mobileHelpOpen = false;
      }
    }
  ];

  helpFunctions = [
    {
      label: 'HELP.FEEDBACK_QUESTION',
      icon: 'assets/img/icons/feedback.svg',
      action: () => {
        this.desktopHelpOpen = !this.desktopHelpOpen;
        this.journeyNavigationService.$openSwitchModal.next(true);
        this.modalService.open(FeedbackModalComponent).dismissed.subscribe((result: { success: boolean, value?: string }) => {
          if(result.success) {
            this.createFeedback({message: result.value as string, type: 'NATIVE_FEEDBACK', url: this.router.url}).subscribe(
              feedback => {
                const ref = this.modalService.open(SimpleModalComponent);
                ref.componentInstance.cancelButton = this.translate.instant('FEEDBACK_MODAL.DONE.CANCEL_BUTTON');
                ref.componentInstance.headerText = this.translate.instant('FEEDBACK_MODAL.DONE.HEADER');
                ref.componentInstance.bodyTitle = this.translate.instant('FEEDBACK_MODAL.DONE.TITLE');
                ref.componentInstance.bodyText = this.translate.instant('FEEDBACK_MODAL.DONE.TEXT');
                ref.dismissed.subscribe( () => {
                  this.journeyNavigationService.$openSwitchModal.next(false);
                });
              }
            )
          } else {
            this.journeyNavigationService.$openSwitchModal.next(false);
          }
        })
      }
    },
    {
      label: 'HELP.VIDEO_TUTORIAL',
      icon: 'assets/img/icons/player.svg',
      action: () => {
        this.journeyNavigationService.$openSwitchModal.next(true);
        this.modalService.open(TutorialModalComponent).dismissed.subscribe( () => {
          this.journeyNavigationService.$openSwitchModal.next(false);
        });
        this.desktopHelpOpen = !this.desktopHelpOpen;
      }
    },
    {
      label: 'HELP.CONTACT_COACH',
      icon: 'assets/img/icons/contact-coach.svg',
      action: () => {
        this.desktopHelpOpen = !this.desktopHelpOpen;
        this.journeyNavigationService.$openSwitchModal.next(true);
        this.modalService.open(ContactModalComponent).dismissed.subscribe((result: { success: boolean, type?: string, value?: string }) => {
          if(result.success) {
            this.createFeedback({ message: result.value as string, type: result.type === 'chat'? 'SUPPORT_CHAT' : 'SUPPORT_CALL', url: this.router.url}).subscribe(
              feedback => {
                const ref = this.modalService.open(SimpleModalComponent);
                ref.componentInstance.cancelButton = this.translate.instant('CONTACT_MODAL.DONE.CANCEL_BUTTON');
                ref.componentInstance.headerText = this.translate.instant('CONTACT_MODAL.DONE.HEADER');
                ref.componentInstance.bodyTitle = this.translate.instant('CONTACT_MODAL.DONE.TITLE');
                ref.componentInstance.bodyText = this.translate.instant('CONTACT_MODAL.DONE.TEXT');
                ref.dismissed.subscribe(() => {
                  this.journeyNavigationService.$openSwitchModal.next(false);
                })
              }
            )
          } else {
            this.journeyNavigationService.$openSwitchModal.next(false);
          }
        })
      }
    }
  ];
  constructor(private translate: TranslateService,
              private modalService: ModalService,
              private feedbackGQL: CreateFeedbackGQL,
              public globalService: GlobalService,
              private router: Router,
              private elRef: ElementRef,
              private journeyNavigationService: JourneyNavigationService,
              private loadingService: LoadingService) {}

  ngOnInit() {
    this.loadingService.$loadingState.subscribe(loadingState => {
      this.loading = loadingState.loading;
    });
    this.parseLabels(this.navLinks);
    this.parseLabels(this.helpFunctions);
  }

  private parseLabels(arr: {label: string}[]) {
  arr.forEach((item, index) => {
    this.translate.get(item.label)
      .pipe(take(1))
      .subscribe((label) => {
        arr[index].label = label;
    });
  });
  }

  private createFeedback(input: CreateFeedbackInput) {
    return this.feedbackGQL.mutate({input});
  }
}
