import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'four-lead-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnInit {
  constructor(private translate: TranslateService) {
  }
  @Input() size = '96px';
  @Input() percent = 60;
  @Input() color = '#4AA578';
  @Input() text = 'default';
  @Input() icon = 'assets/img/icons/hourglass.svg';

  public percentVal: string;
  public accentColor: string;
  ngOnInit() {
    this. percentVal = this.convertToPercent(this.percent)

    if (this.percent === 100) {
      this.text = this.translate.instant('GLOBAL.DONE');
      this.icon = 'assets/img/icons/check-mark.svg';
      this.color = '#1D7C4D';
      this.accentColor = '#1D7C4D';
    }
    if(this.percent === 0) {
      this.text = this.translate.instant('GLOBAL.NULL');
      this.icon = 'assets/img/icons/close-thin.svg';
      this.color = '#F26464';
      this.accentColor = '#F26464';
    }
  }

  convertToPercent(number: number): string {
    return number + '%';
  }
}
