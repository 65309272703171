import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {ModalService} from "../../../../services/modal/modal.service";
import {fromEvent} from "rxjs/internal/observable/fromEvent";
import {Subscription} from "rxjs";

@Component({
  selector: 'four-lead-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnDestroy{
  @Input() headerIcon = ''
  @Input() headerText = ''
  @Input() cancelButton = ''
  @Input() confirmButton = '';
  @Input() confirmDisabled = false;
  @Input() normalConfirmButton = true;
  @Input() showFooter = true;
  @Output() output: EventEmitter<string> = new EventEmitter<string>();
  @Input() noKeyDown = false;

  private sub: Subscription;

  constructor(private modalService: ModalService) {
    setTimeout(() => {
      this.sub = fromEvent<KeyboardEvent>(document, 'keydown')

          .subscribe((next: KeyboardEvent) => {
            if(next.key === 'Enter' && !this.noKeyDown && !this.confirmDisabled && !next.shiftKey){
              this.confirmModal();
            }
          })
    }, 500)
  }

  ngOnDestroy() {
    if(this.sub) this.sub.unsubscribe();
  }

  public cancelModal(): void {
    this.output.emit('cancel');
  }

  public confirmModal(): void  {
    this.output.emit('confirm');
  }
}
