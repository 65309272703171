<four-lead-modal [headerIcon]="headerIcon"
                 [headerText]="headerText"
                 [cancelButton]="cancelButton"
                 [normalConfirmButton]="!redConfirmButton"
                 [confirmButton]="confirmButton"
                 (output)="handleOutput($event)">
    <h1>
        {{bodyTitle}}
        <span class="subline">{{bodyText}}</span>
    </h1>
</four-lead-modal>
