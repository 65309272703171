import {Component, Input} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'four-lead-expansion-panel-body',
  templateUrl: './expansion-panel-body.component.html',
  styleUrls: ['./expansion-panel-body.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({ height: '*', padding: '*' })),
      state('false', style({ height: '0px', padding: '0px' })),
      transition('false <=> true', [animate('.5s cubic-bezier(0.16, 1, 0.3, 1)')])
    ])
  ]
})
export class ExpansionPanelBodyComponent {
  @Input() expanded = false;
  @Input() withBorder = false;
}
