import { Component } from '@angular/core';
import {ModalService} from "../../../services/modal/modal.service";

@Component({
  selector: 'four-lead-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss'],
})
export class ContactModalComponent {

  public contactType: 'chat' | 'call' = 'chat';
  public inputText = '';
  constructor(private modalService: ModalService) {}

  public handleOutput(event: string) {
    if(event === 'confirm') {
      this.modalService.close({success: true, type: this.contactType,  value: this.inputText});
    } else if (event === 'cancel') {
      this.modalService.close({success: false});
    }
  }
}
