<div style="display: flex; justify-content: center">
  <div style="padding: 2rem; max-width: 850px;">
    <four-lead-pie-chart></four-lead-pie-chart>
    <hr>
    <br>
    <div style="display: flex; gap: 1rem">
      <four-lead-button [buttonStyle]="'button--primary'" (click)="openTestModal()">Open Modal Normal</four-lead-button>
      <four-lead-button [buttonStyle]="'button--danger'" (click)="openTestModal(true, false)">Open Modal Danger</four-lead-button>
      <four-lead-button [buttonStyle]="'button--secondary'" (click)="openTestModal(false)">Open Modal without Footer</four-lead-button>
    </div>
    <hr>
    <br>
    <div style="display: flex; gap: 0.25rem">
      <four-lead-state-dot *ngFor="let dotState of dots; let i = index"
                           [dotState]="dotState"
                           (click)="setWaitingDot(i)"></four-lead-state-dot>
    </div>
    <hr>
    <br>
    <div style="display: flex; gap: 1rem">
      <four-lead-button
              [buttonStyle]="'button--primary'"
      >Button big
      </four-lead-button>

      <four-lead-button
              [buttonStyle]="'button--secondary'"
      >Button big
      </four-lead-button>

      <four-lead-button
              [buttonStyle]="'button--danger'"
      >Button big
      </four-lead-button>

      <four-lead-button
              [buttonStyle]="'button--secondary'"
              [buttonSize]="'button--small'"
      >Button small
      </four-lead-button>

      <four-lead-button
              [buttonStyle]="'button--primary'"
              [icon]="'assets/img/icons/enter-with-frame.svg'">Icon Button
      </four-lead-button>

      <four-lead-button
              [buttonStyle]="'button--secondary'"
              [icon]="'assets/img/icons/enter-with-frame-dark.svg'">Icon Button
      </four-lead-button>

      <four-lead-button
              [buttonStyle]="'button--secondary'"
              [iconAlign]="'button--icon-left'"
              [icon]="'assets/img/icons/enter-with-frame-dark.svg'">Icon Button left
      </four-lead-button>
    </div>
    <hr>
    <br>
    <four-lead-content-card
            [cardHeaderText]="'Lorem Ipsum Dolor'"
            [cardHeaderIcon]="'assets/img/icons/graduate-hat.svg'"
            [cardHeader]="true"
    >
      <h1>Card with header</h1>
    </four-lead-content-card>
    <br>
    <four-lead-content-card>
      <h1>Card without header
        <span class="subline">Directly resume one of your open journeys:</span>
      </h1>
    </four-lead-content-card>
    <hr>
    <br>
    <div class="sign-in__input-wrapper input">
      <label class="input__label">Email address</label>
      <input
        id="e-mail"
              type="text"
              class="input__text-input"
              autoComplete='off'>
    </div>
    <div class="sign-in__input-wrapper input">
      <label class="input__label">Password</label>
      <input
        id="new-password"
              [type]="isTypeText ? 'text' : 'password'"
              class="input__text-input"
              autocomplete="new-password">
      <img
              (click)="toggleIsTypeText()"
              [src]="'assets/img/icons/eye.svg'"
              class="input__text-input-icon"
              alt="show password icon">
    </div>
    <br>
    <div class="sign-in__input-wrapper input">
      <label class="input__label">Email address</label>
      <input
        id="e-mail-2"
              type="text"
              class="input__text-input input__text-input--has-error"
              autoComplete='off'>
    </div>
    <hr>
    <br>
    <four-lead-button [buttonStyle]="'button--secondary'" (click)="addNewPostItClick()">
      add more postits
    </four-lead-button>
    <br>
    <br>
    <div style="display: flex; gap: 0.5rem">
      <four-lead-post-it-button *ngFor="let postIt of postIts; let i = index"
                                [color]="postIt.color"
                                [text]="postIt.text"
                                (output)="handlePostItClick($event, i)"></four-lead-post-it-button>
    </div>
    <hr>
    <br>
    <div style="display: flex; gap: 0.5rem; flex-wrap: wrap;">
      <four-lead-ratio-tab *ngFor="let ratioTab of ratioTabs; let i = index"
                           [ratioTitle]="ratioTab.title"
                           [ratioText]="ratioTab.text ?? ''"
                           [ratioSelected]="i === selectedRatio"
                           (click)="handleRatioClick(i)"></four-lead-ratio-tab>
    </div>
    <hr>
    <br>
    <div style="display: flex; flex-direction: column; gap: 1.5rem">
      <four-lead-expansion-panel-wrapper *ngFor="let expansionPanel of expansionPanels; let i = index" (expandedChange)="updatePanels(i, $event)">
        <div header>
          <div class="header">
            <div class="header__img-wrapper">
              <svg-icon src="{{expansionPanel.headerIcon}}"/>
            </div>
            <div class="header__text-wrapper">
              <h2 class="title">{{expansionPanel.title}}</h2>
              <h3 class="subline">{{expansionPanel.subtitle}}</h3>
            </div>
            <four-lead-button class="button--{{checkPanelExpanded(i)? 'open' : 'close'}}"
                              [buttonStyle]="'button--secondary'"
                              [icon]="'assets/img/icons/arrow-down.svg'"
                              [iconOnly]="true"></four-lead-button>
          </div>
        </div>
        <div body class="body">
          <four-lead-ratio-tab *ngFor="let ratioTab of ratioTabs; let i = index"
                               [ratioTitle]="ratioTab.title"
                               [ratioText]="ratioTab.text ?? ''"
                               [ratioSelected]="i === selectedRatio"
                               (click)="handleRatioClick(i)"></four-lead-ratio-tab>
        </div>
      </four-lead-expansion-panel-wrapper>
    </div>
    <hr>
    <br>
    <four-lead-slider [name]="'Schwäche'"></four-lead-slider>
    <hr>
    <br>
  </div>
</div>

