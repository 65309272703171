<nav class="nav" *ngIf="!loading">
  <div class="nav__left">
    <a routerLink="/" style="line-height:0">
      <svg-icon src="assets/img/logo-variants/logo-white.svg"
                class="nav__logo"
                [svgClass]="'nav__logo'"
           />
    </a>
    <div class="nav__links mobileHidden" *ngIf="!empty">
      <a *ngFor="let link of navLinks"
          class="nav__links__link"
         [routerLinkActive]="'nav__links__link--active'"
         [routerLink]="link.link">
        {{link.label}}</a>
    </div>
  </div>
  <div class="nav__right" *ngIf="!empty">
    <four-lead-user-auth-display></four-lead-user-auth-display>
  </div>
</nav>

<div class="help-button mobileHidden" *ngIf="!empty">
  <button class="help-button__button" (click)="desktopHelpOpen = !desktopHelpOpen">
    <svg-icon class="svg" src="assets/img/icons/help.svg"></svg-icon>
  </button>
  <four-lead-context-menu
    alignX="left"
    alignY="top"
    [(open)]="desktopHelpOpen">
    <ng-container *ngTemplateOutlet="helpMenu"></ng-container>
  </four-lead-context-menu>
</div>

<div class="bottom-nav" *ngIf="!empty"
     [class.bottom-nav--journey-open]="globalService.journeyOpen"
>
  <div class="bottom-nav__item-wrapper">
    <a class="bottom-nav__item"
       *ngFor="let item of navLinks"
       (click)="item.action()"
       [routerLink]="item.link"
       [routerLinkActive]="'bottom-nav__item--active'"
       >
      <svg-icon [src]="item.icon" [svgClass]="'bottom-nav__item__icon'"></svg-icon>
      <span class="bottom-nav__item__text">{{item.label}}</span>
    </a>

    <button class="bottom-nav__item"
            [ngClass]="mobileHelpOpen ? 'bottom-nav__item--active' : ''"
            (click)="mobileHelpOpen = !mobileHelpOpen">
      <svg-icon [src]="'assets/img/icons/help.svg'" [svgClass]="'bottom-nav__item__icon'"></svg-icon>
      <span class="bottom-nav__item__text">Help</span>
    </button>
  </div>

  <div class="bottom-nav__help-menu" id="help-button" [class.bottom-nav__help-menu--open]="mobileHelpOpen">
    <ng-container *ngTemplateOutlet="helpMenu"></ng-container>
  </div>
</div>


<ng-template #helpMenu>
  <button class="bottom-nav__help-menu__button"
          *ngFor="let help of helpFunctions"
          (click)="help.action()">
    <svg-icon [src]="help.icon"></svg-icon>
    <span>{{help.label}}</span>
  </button>
</ng-template>
