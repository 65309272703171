import {AfterViewInit, Component, Input} from '@angular/core';

@Component({
  selector: ' four-lead-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss'],
})
export class ContentCardComponent implements AfterViewInit {
  @Input() cardHeader = false;
  @Input() cardHeaderIcon = '';
  @Input() cardHeaderText = '';
  @Input() asMobileSection = false;

  ngAfterViewInit() {
  }
}


