import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'four-lead-text-area-input',
  templateUrl: './text-area-input.component.html',
  styleUrls: ['./text-area-input.component.scss'],
})
export class TextAreaInputComponent implements OnInit, AfterViewInit{
  @Input() value?: string = '';
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() focus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() subLine: string = 'GLOBAL.START_TYPING';
  @Input() placeholder: string = '';
  @Input() readonly : boolean = false;
  @Input() maxLength?: number;
  @Input() minLength?: number;
  @Input() id: string = '';

  @ViewChild('mainTextArea') mainTextArea!: ElementRef<HTMLTextAreaElement>;

  ngOnInit() {
    if (!this.id) {
      this.id = Math.random().toString(36).substring(7) + Math.random().toString(36).substring(7);
    }
  }

  ngAfterViewInit() {
    this.calcNewHeight();
  }

  changeValue(val: Event) {
    this.value = this.mainTextArea.nativeElement.value;
    this.valueChange.emit(this.value);
    this.calcNewHeight();
  }

  calcNewHeight() {
    this.mainTextArea.nativeElement.style.height = 'auto';
    this.mainTextArea.nativeElement.style.height = this.mainTextArea.nativeElement.scrollHeight >= 24 ? this.mainTextArea.nativeElement.scrollHeight + 'px' : '24px';
  }
}
