import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'four-lead-expansion-panel-header',
  templateUrl: './expansion-panel-header.component.html',
  styleUrls: ['./expansion-panel-header.component.scss'],
})
export class ExpansionPanelHeaderComponent {
  @Output() headerClick = new EventEmitter<void>();
  togglePanel() {
    this.headerClick.emit();
  }
}
